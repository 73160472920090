import 'slick-carousel';
import momemt from 'moment';
import 'magnific-popup';
jQuery( document ).ready(function($) {
  // Functions
  const activeThisOnClick = (e) => {
    e.currentTarget.classList.toggle('active') 
  }
  const removeActiveNavItems = () => {
    $('.nav a').removeClass('active')
  }
  const toggleMenu = () => {
    document.querySelector('header .main').classList.toggle('active')
  }
  const hideMenu = () => {
    document.querySelector('header .main').classList.remove('active')
  }
  const showSection = (e) => {
    const target = e.currentTarget.getAttribute('href')
    $('section').removeClass('active')
    $(`${target}`).addClass('active')
  }
  const toggleAllVideos = (e) => {
    e.preventDefault()
    $('#videos').toggleClass('all')
  }
  // Events
  $('.menu-trigger').click(function(e){
    activeThisOnClick(e)
    toggleMenu()
  })
  $('.header-logo').click(function(e){
    removeActiveNavItems()
    activeThisOnClick(e)
    showSection(e)
    toggleMenu()
  })
  $('.nav .toggle').click(function(e){
    removeActiveNavItems()
    activeThisOnClick(e)
    showSection(e)
    hideMenu()
    $('.menu-trigger').removeClass('active') 
  })
  $('.btn-all-videos').click(toggleAllVideos)
  // Sliders
  $('.slider-music').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: 'unslick'
      }
    ]
  });
  // videos hover
  $(".video-wrapper.has-vid").hover(function () {
    $(this).children("video")[0].play();
  }, function () {
      var el = $(this).children("video")[0];
      el.pause();
      el.currentTime = 0;
  });
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/id_15513941/events?app_id=45PRESS_larosa',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let location = event.venue.city + ', ' + event.venue.region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
        html += '<div class="event-info">';
        html += '<div class="event-location">' + location + '</div>';
        html += '<div class="event-venue">' + event.venue.name + '</div>';
        html += '</div>';
        html += '<div class="event-links">';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-white-outline">' + offer.type + '</a>';
        }
        html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });
  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 60) {
      $("header .top").addClass("sticky");
    } else{
      $("header .top").removeClass("sticky");
    }
  });

  $('.newsletter').on('submit', function(e) {
      e.preventDefault();
      const DATA = $(this).serialize();

      $.ajax({
          type: 'POST',
          url: $(this).attr('action'),
          dataType: 'json',
          data: DATA,
          xhrFields: {
              withCredentials: false,
          },
          success: function (data) {
              $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
          },
          error: function (err) {
              console.log(err);
          }
      });
  });
  
  // Apple popup
  $('.apple-btn').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: '#apple-popup',
        type: 'inline'
      }
    });
    var sm = new SMEAppleMusic({
      ae: {
        ae: "db1275a443487a733c0a9127248d15de1f9e914d0e58fb57d1e3dbf16b7d6167",
        brand_id: "4878302",
        segment_id: "2364112",
        activities: '{"actions":{"presave":164449},"mailing_list_optins":{"a0S0800000W8ZMpEAN":164450}}'
      },
      am: {
        dev_token: "",
        save_mode: "library",
        custom_playlist_name: "",
        new_apple_presave: true,
        resources: {
          playlists: [],
          albums: []
        }
      },
      sf: {
        form: "551548",
        default_mailing_list: ""
      },
      smf: {
        campaign_id: "551548",
        campaign_key: "93f91e9a41c6bc8b4761228a3bf93798"
      }
    });


    //apple music form
    document.getElementById("apple_music_form").addEventListener("submit", function (e) {
      e.preventDefault();
      // Grab the email address value.
      var email = document.getElementById("apple_music_email_address").value;
      // Pass the email address and opt in status
      // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
      sm.doActions(email, false, ["a0S0800000tmzIGEAY"]).then(function (res) {
        // REPLACE THIS ^

        $.magnificPopup.open({
          items: {
            src: '#thanks',
            type: 'inline'
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    });
  });

  //Spotify / Deezer
  let SMEPreSave = {
    state: null,
    spotify_user: null,
    url_vars: [],
    getURLVars: function (callback, form_id) {
      let hash;
      let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        this.url_vars.push(hash[0]);
        this.url_vars[hash[0]] = hash[1];
      }
      this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
      this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
      this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
      this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
      this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
      if (typeof callback === 'function') {
        callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
      }
    }
  };
  /* String state
  *  The status of the pre-save.
  *  Int ae_member_id
  *  The Appreciation Engine member ID. This can be passed to the forms processor.
  *  String spotify_user
  *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
  *  String deezery_user
  *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
  */
  SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
    // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
    if (state === 'success' || state === 'thank-you') {
      $.magnificPopup.open({
        items: {
          src: '#thanks',
          type: 'inline'
        }
      });
    }
  });
});